import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { breakpointQuery, sectionMargins, brandColours } from '../utilities';
import Container from './Container';
import Rocket from '../images/rocket.svg';

const StyledNotFoundContent = styled.section`
  ${sectionMargins()};
  text-align: center;
`;

const StyledText = styled.p`
  margin-bottom: 20px;
  line-height: 1.7;

  ${breakpointQuery.tiny`
    margin-bottom: 24px;
  `};

  ${breakpointQuery.medium`
    margin-bottom: 28px;
  `};

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: ${brandColours.tertiary};
  }
`;

const StyledImage = styled.img`
  margin-bottom: 20px;
  max-width: 80px;

  ${breakpointQuery.tiny`
    margin-bottom: 24px;
  `};

  ${breakpointQuery.medium`
    margin-bottom: 28px;
  `};
`;

const NotFoundContent = () => (
  <StyledNotFoundContent>
    <Container narrow={true}>
      <StyledText>The page you are looking for does not exist.</StyledText>
      <StyledImage src={Rocket} loading="lazy" alt="Barcanet Logo" />
      <StyledText>
        Go back to the <Link to="/">launchpad</Link> or{' '}
        <Link to="/contact/">contact us</Link> about this problem.
      </StyledText>
    </Container>
  </StyledNotFoundContent>
);

export default NotFoundContent;
