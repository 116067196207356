import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import NotFoundContent from '../components/NotFoundContent';

const NotFoundPage = () => {
  return (
    <Layout>
      <HelmetDatoCms title="404, Page Not Found | Barcanet" />
      <main>
        <Banner heading="Oops." text="You are on the wrong trajectory!" />
        <NotFoundContent />
      </main>
    </Layout>
  );
};

export default NotFoundPage;
